@import "@fontsource/roboto";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.safe-h-screen {
  /* equivalent to 100dvh in major browsers */
  height: calc(
    100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
}

@supports (-webkit-touch-callout: none) {
  .safe-h-screen {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    height: -webkit-fill-available;
  }
}

$ig-small-screen: 768px !default;
$ig-xsmall-screen: 480px !default;

.image-gallery-download-button {
  bottom: 0;
  padding: 22px 20px;

  left: 50%;
  transform: translateX(-50%);

  .image-gallery-svg {
    height: 28px;
    width: 28px;
  }

  @media (max-width: $ig-small-screen) {
    padding: 15px;

    .image-gallery-svg {
      height: 24px;
      width: 24px;
    }
  }

  @media (max-width: $ig-xsmall-screen) {
    padding: 10px;

    .image-gallery-svg {
      height: 16px;
      width: 16px;
    }
  }
}
